<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 이벤트 리스트 ------------------------------------------------------>

    <CCard>
      <CCardHeader>
        <BRow>
          <BCol sm="2">
            <BIconBellFill/> 경보 조회
          </BCol>
          <BCol class="small">
            <BInputGroup size="sm">
              <BInputGroupAppend>
                <BFormSelect size="sm" v-model="tid" @input="getList" :options="tankOpts" style="background-color: #2f303a"/>
                <BFormSelect size="sm" v-model="evtCode" @input="getList" :options="eventOpts" style="background-color: #2f303a"/>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
          <BCol sm="6">
            <BInputGroup size="sm">
              <BFormSelect size="sm"
                           v-model="fromDays"
                           style="background-color: #2f303a"
                           @input="selectDayOpts"
                           :options="dayFromOpts"
              />
              <BFormInput size=sm type="date" v-model="fromDts"></BFormInput>
              <BInputGroupAppend size="sm">
              <BInputGroupText  class="small">~</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput size=sm type="date" v-model="toDts"></BFormInput>
              <BInputGroupAppend size="sm">
                <BButton variant="info" size="sm" @click="getList"><BIconSearch/> 검 색</BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody>

        <BTable
          ref="eventTable"
          sticky-header
          sticky-column
          small
          select-mode="single"
          selected-variant="primary"
          :per-page="perPage"
          :current-page="page"
          thead-class="text-center"
          head-variant="light"
          class="text-nowrap small"
          :fields="eventFields"
          :items="rows"
          @row-selected="rowSelected"
        >
          <template #cell(tnm)="{item}">
             {{ tankMap[item.tid].name }}
          </template>

          <template #cell(tankCode)="{item}">
            <BBadge class="font-sm">{{ tankMap[item.tid].tankCode }}</BBadge>
          </template>


          <template #cell(name)="{item}">
            <BBadge class="font-sm" :variant="eventColor[item.code]">{{ item.name }}</BBadge>
          </template>


          <template #cell(text)="{item}">
            {{ item.text }}
          </template>
        </BTable>

        <BRow>
          <BCol>
            <BButton variant="warning"
                     size="sm"
                     v-if="totalRows!==0"
                     v-b-modal.download-modal>
              <BIconDownload/> <b>로그 다운로드</b>
            </BButton>
          </BCol>
          <BCol>
            <BPagination
              align="right"
              size="sm"
              v-model="page"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="eventTable"
            ></BPagination>
          </BCol>
        </BRow>



      </CCardBody>
    </CCard>
    <CRow v-if="isShowGraph">
      <CCol>
        <CCardGroup columns class="card-columns cols-3">
          <CCard v-for="item in dataMap" :key="item.tid">
            <CCardHeader>
              <BBadge variant="info" class="font-sm">{{ tankMap[item.tid]?.name }}</BBadge> <BBadge>{{tankMap[item.tid]?.tankCode}}</BBadge>
            </CCardHeader>
            <CCardBody>
              <CChartPie
                style="min-height: 300px"
                :datasets="[item.datasets]"
                :labels="labelsMap[item.tid]"
              />
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>

    <BModal ref="download-modal" id="download-modal" hide-footer
            header-bg-variant="warning"
            title="다운로드 사유 입력">
      <BFormInput class="mb-1" v-model="downloadReason" placeholder="5글자 이상 작성해주세요"/>
      <json-excel
        v-show="downloadReason.length > 4"
        class="btn btn-block btn-danger"
        :data="rows"
        :fields="excelFields"
        worksheet="AccessLog"
        :before-generate="exportExcel"
        :before-finish= "finishDownload"
        type="xlsx"
        :name="fileName">
        <BIconDownload/> <b>다운로드</b>
      </json-excel>

    </BModal>


  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  accessLogging,
  apiCall, toastSync,
} from '@/common/utils';
import moment from "moment";
import qs from 'querystring';
import { CChartPie } from '@coreui/vue-chartjs'
import JsonExcel from "vue-json-excel";


// $blue: #007bff !default;
// $indigo: #6610f2 !default;
// $purple: #6f42c1 !default;
// $pink: #e83e8c !default;
// $red: #dc3545 !default;
// $orange: #fd7e14 !default;
// $yellow: #ffc107 !default;
// $green: #28a745 !default;
// $teal: #20c997 !default;
// $cyan: #17a2b8 !default;


const _eventBgColor = {
    '1':'#ffc107', // 넘침
    '2':'#007bff', // 부족
    '3':'#ff0000',  // 화재
    '4':'#1730b8',    // 수분
    '5':'#10dF20',  // 누유
    '6':'#ff3545',  // 넘침2
    '7':'#201020',  // 부족2
    '8':'#f8f9fa',   // 점검
    '9':'#6610f2'  // 점검
}


//----------------------------------------------------------------------------------------------------
export default {
  name: "Events",
  components: {
    CChartPie,
    JsonExcel
  },
  props: {
    from: { type: Number, default: 3 },
    code: { type: String, default: '' },
    tankId: { type: String, default: '' },
  },
  data () {
    return {
      tid: '',
      evtCode: '',

      pickerShow: false,
      eventCodeMap: this.$store.state.codeMaps['EVENT'],
      tankOpts : this.$store.state.tanks['opts'],
      tankMap : this.$store.state.tanks['map'],
      eventOpts : this.$store.state.codeOpts['EVENT'],
      isCreateMode: false,
      rows: [],
      eventFields: [
        { key: 'createdAt', sortable: true, tdClass: 'font-weight-bold', tdStyle: 'min-width: 300px', label:'경보일시',formatter: v=>this.toLocalTimeSec(v) },
        { key: 'dt', sortable: true, tdClass: 'font-weight-bold', tdStyle: 'min-width: 300px', label:'발생일시',formatter: v=>this.toLocalTimeSec(v) },
        { key: 'tnm'      , sortable: true, tdClass: 'text-center font-weight-bold', label:'탱크명'},
        { key: 'tankCode' , sortable: true, tdClass: 'text-center font-weight-bold', label:'탱크코드' },
        // { key: 'tid'      , sortable: true, tdClass: 'text-center font-weight-bold', label:'탱크번호'},
        { key: 'code'     , sortable: true, label:'경보코드', tdClass: 'text-center font-weight-bold'},
        { key: 'name'     , sortable: true, label:'경보명'},
        { key: 'text'  ,  sortable: true, label:'경보내용' },
      ],
      page: 1,
      perPage: 10,
      totalRows: 0,
      formShow: false,
      isSubmitting: false,
      row: null,
      eventColor: {
        '1':'warning', // 넘침
        '2':'primary', // 부족
        '3':'danger',  // 화재
        '4':'info',    // 수분
        '5':'success',  // 누유
        '6':'danger',  // 넘침2
        '7':'dark',    // 부족2
        '8':'light',   // 점검
        '9':'secondary'  // 센서점검
      },
      fromDays: 3,
      fromDts: moment().subtract(this.fromDays, 'days').format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      dayFromOpts: [
        {value:3, text:'최근 3일'},
        {value:7, text:'최근 7일'},
        {value:14, text:'최근 2주'},
        {value:28, text:'최근 4주'},
        {value:90, text:'최근 3개월'},
      ],
      labelsMap: {},
      dataMap: {},
      isShowGraph: false,
      downloadReason: '',
      excelFields: {
        '경보일시': {field: 'createdAt', callback: (v)=>{ return moment(v).format('YYYY-MM-DD HH:mm:ss')}},
        '발생일시': {field: 'dt', callback: (v)=>{ return moment(v).format('YYYY-MM-DD HH:mm:ss')}},
        '탱크명': {field: 'tid', callback: (v)=>{ return this.tankMap[v]?.name }},
        '탱크코드': {field: 'tid', callback: (v)=>{ return this.tankMap[v]?.tankCode }},
        '경보코드': 'code',
        '경보명': {field: 'code', callback: (v)=>{ return this.eventCodeMap[v] }} ,
        '경보내용': 'text'
      },
      fileName: '',

    }

  },
  async created(){
    try{
      this.fromDays = this.from? this.from: this.fromDays;
      this.evtCode = this.code? this.code: this.evtCode;
      this.tid = this.tankId? this.tankId:this.tid;

      this.fromDts =  moment().subtract(this.fromDays, 'days').format('YYYY-MM-DD');
      this.toDts = moment().format('YYYY-MM-DD');
      await this.getList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {
    userRows(){
      return this.rows.length;
    }
  },

  mounted() {
    console.log("--- Events mounted---------------------");
    // below is not work!
  },

  methods: {
    async selectDayOpts(){
      this.toDts = moment().format('YYYY-MM-DD');
      this.fromDts = moment().subtract(this.fromDays,'days').format('YYYY-MM-DD');
      await this.getList();
    },


    async getList(){
      let query= '';
      let paramMap = {};
      this.isShowGraph = false;
      this.labelsMap = {};
      this.dataMap = {};
      // if(!this.tid &&  this.tankOpts.length > 1 ){
      //   this.tid = this.tankOpts[1].value;
      // }

      try{

        // console.log( "getList() --- query-string ----> ");
        if(this.tid) paramMap['tid'] = this.tid;
        if(this.evtCode) paramMap['code'] = this.evtCode;
        if(this.fromDts) paramMap['fromDts'] = this.fromDts;
        if(this.toDts) paramMap['toDts'] = this.toDts;
        query = qs.stringify(paramMap);
        // console.log('getList---query:', query);
        this.rows = [];
        const r = await apiCall('get', `/api/event?${query}`);
        if(r.code===200){
          this.rows = r.result.rs;
          this.totalRows = r.result.rs.length;
          await this.toastInfo(`경보 정보 ${this.rows.length}건 조회됨`, 'info');
          this.makeGraphData(r.result.statRs)
        }

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },
    makeGraphData(rs){
      // console.log('stat rs --->', rs)
      let labelsMap = this.labelsMap;
      let dataMap = this.dataMap;
      rs.map(r=>{
        labelsMap[r.tid] = [];
        dataMap[r.tid] = { tid: r.tid, datasets: { backgroundColor:[], data: [] } };
      })

      rs.map(r=>{
       labelsMap[r.tid].push( this.eventCodeMap[r.code] )
       dataMap[r.tid].datasets.data.push( r.count );
       dataMap[r.tid].datasets.backgroundColor.push( _eventBgColor[r.code] );
      })
      this.isShowGraph = true;
    },

    rowSelected(){
      return true;
    },

    async exportExcel(){
      try{
        this.fileName = `event-log_${this.fromDts}_${this.toDts}.xls`;

        console.log( '------before-generate startDownload-----------', this.fileName);
        if( !this.downloadReason || this.downloadReason.length < 5 ){
          return await toastSync(this.$bvToast, "[사유 없음] 다운로드 사유는 5자 이상 입력해야 합니다.", 'danger');
        }
        const {currentRoute} = this.$router;
        const rs = await accessLogging(
          'download',
          currentRoute,
          '',
          this.downloadReason,
          `레코드수: ${this.rows.length}, 파일명: ${this.fileName}`
        );

        if(rs===true){
          this.downloadReason = '';
          return true;
        }else{
          await toastSync(this.$bvToast, "Error: "+rs.message, 'danger');
          return false;
        }

      }catch(err){
        console.log(err);
        return false;
      }
    },

    finishDownload(){
      this.$refs['download-modal'].hide();
    },



  }, // end of methods
  watch:{
    '$route.query': {
      handler: function(query) {
        // console.log('watch params--->', query);
        this.evtCode = query.code;
        this.fromDays = query.from;
      },
      deep: true,
      immediate: true
    },/*
    code(newVal, oldVal){
      console.log('[props] "code" changed: ', newVal, ' | was: ', oldVal);
    },
    from(newVal, oldVal){
      console.log('[props] "from" changed: ', newVal, ' | was: ', oldVal);
    },
    tankId(newVal, oldVal){
      console.log('[props] "tankId" changed: ', newVal, ' | was: ', oldVal);
    }*/
  }
}
</script>
